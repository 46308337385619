
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { laborDataIndependentInterface } from '@/utils/generalInterface'
  import { mapActions } from 'vuex'
  import GDatePicker from '@/components/core/input/GDatePicker.vue'
  import { RULES } from '@/components/forms'
  import { FileParameter } from '@/entities/files'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { Debounce } from '@/utils/decorators'
  import { LaborData } from '@/entities/persons'
  import GCostField from '@/components/core/input/GCostField.vue'
  import { GForm } from '@/components/forms/GForm'

@Component({
  components: { GCostField, GFiles, GDatePicker },
  methods: {
    ...mapActions('persons/labor', ['sendLaborIndependent']),
  },
  computed: {},
})
  export default class LaborDataIndependent extends GForm {
  @Prop({ type: LaborData, default: () => null }) info!: LaborData;
  @Prop({ type: Array, default: () => [] }) files!: FileParameter[];
  @Prop({ type: Number, default: null }) idProcess!: number;
  @Prop({ type: Boolean, default: false }) send!: boolean;
  @Prop({ type: Boolean, default: false }) closeForm!: boolean;
  @Prop({ type: Number, default: null }) idPerson!: number
  @Prop({
    type: Object,
    default: () => {
    },
  }) value!: any;

  $refs!: {
    form2: HTMLFormElement
  };

  valid = false
  disabledField = true
  fieldRequired = RULES.isRequired
  data: laborDataIndependentInterface = {
    rent: '',
    siiKey: '',
    dateEmployment: '',
    retirementLetter: [],
    leaseContract: [],
    commercialPatent: [],
    taxFolder: [],
    comments: '',
  }

  fields = {
    taxFolder: {
      properties: {
        accept: '',
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
        icon: '',
      },
    },
    commercialPatent: {
      properties: {
        accept: '',
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
        icon: '',
      },
    },
    retirementLetter: {
      properties: {
        accept: '',
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
        icon: '',
      },
    },
    leaseContract: {
      properties: {
        accept: '',
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
        icon: '',
      },
    },
  }

  sendLaborIndependent!:
    ({ data, idPerson }: { data: laborDataIndependentInterface, idPerson: number }) => Promise<{ id: number }>;

  @Watch('send')
  async onSend (): Promise<void> {
    if (!this.$refs.form2.validate()) {
      await this.setFieldError()
      this.$emit('send')
      return
    }

    if (this.info?.id) {
      await this.updateLabor(this.info?.id)
      return
    }

    const resp = await this.sendLaborIndependent({ data: this.data, idPerson: this.idPerson })

    if (this.data?.taxFolder?.length) {
      await this.handleFileType(this.data.taxFolder, { properties: this.fields.taxFolder.properties }, this.idProcess, resp.id)
    }

    if (this.data?.commercialPatent?.length) {
      await this.handleFileType(this.data.commercialPatent, { properties: this.fields.commercialPatent.properties }, this.idProcess, resp.id)
    }

    if (this.data?.retirementLetter?.length) {
      await this.handleFileType(this.data.retirementLetter, { properties: this.fields.retirementLetter.properties }, this.idProcess, resp.id)
    }

    if (this.data?.leaseContract?.length) {
      await this.handleFileType(this.data.leaseContract, { properties: this.fields.leaseContract.properties }, this.idProcess, resp.id)
    }

    this.clearLaborInfo()
    this.$emit('close')
  }

  async updateLabor (id) {
    const { data, fields, idProcess } = this

    if (data?.taxFolder?.length) {
      await this.handleFileType(data.taxFolder, { properties: fields.taxFolder.properties }, idProcess, id)
    }

    if (data?.commercialPatent?.length) {
      await this.handleFileType(data.commercialPatent, { properties: fields.commercialPatent.properties }, idProcess, id)
    }

    if (data?.retirementLetter?.length) {
      await this.handleFileType(data.retirementLetter, { properties: fields.retirementLetter.properties }, idProcess, id)
    }

    if (data?.leaseContract?.length) {
      await this.handleFileType(data.leaseContract, { properties: fields.leaseContract.properties }, idProcess, id)
    }

    await this.close()
  }

  clearLaborInfo (): void {
    this.data = {
      rent: '',
      siiKey: '',
      dateEmployment: '',
      retirementLetter: [],
      leaseContract: [],
      commercialPatent: [],
      taxFolder: [],
      comments: '',
    }
  }

  setProperties (fileInfo, fileTypeName, fieldKey) {
    if (!fileInfo?.length) return
    const info = fileInfo.find(fileParameter => fileParameter.name === fileTypeName)

    if (info) {
      this.fields[fieldKey].properties.accept = info.fileType.mimes
      this.fields[fieldKey].properties.multiple = info.multiple
      this.fields[fieldKey].properties.fileTypeId = info.fileType.id
      this.fields[fieldKey].properties.name = info.name
      this.fields[fieldKey].properties.label = info.description
      this.fields[fieldKey].properties.required = info.required
      this.fields[fieldKey].properties.icon = info.fileType.icon
    }
  }

  mounted () {
    const { value } = this

    if (value) {
      this.data = {
        dateEmployment: value.admissionDate,
        rent: value.rent,
        id: value.id,
        siiKey: value.jobKey,
        comments: value.executiveComment,
      }

      this.disabledField = true
    } else {
      this.clearLaborInfo()
    }
  }

  @Watch('files', { immediate: true, deep: true })
  onFilesChange (val: FileParameter[]): void {
    if (!val?.length) return
    this.setProperties(val, 'tax_folder', 'taxFolder')
    this.setProperties(val, 'commercial_patent', 'commercialPatent')
    this.setProperties(val, 'retirement_letter', 'retirementLetter')
    this.setProperties(val, 'lease_contract', 'leaseContract')
  }

  @Watch('info', { immediate: true, deep: true })
  @Debounce(1000)
  async onInfoChange (val: LaborData): Promise<void> {
    if (!val) return
    const files = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: { _and: [{ id_process_record: { _eq: val.id } }, { parameter: { process: { id: { _eq: this.idProcess } } } }] },
      force: true,
    })

    const leaseContract = files.filter(file => file.parameter.name === 'lease_contract')
    const retirementLetter = files.filter(file => file.parameter.name === 'retirement_letter')
    const commercialPatent = files.filter(file => file.parameter.name === 'commercial_patent')
    const taxFolder = files.filter(file => file.parameter.name === 'tax_folder')

    this.data = {
      id: val.id,
      rent: val.rent?.toString(),
      siiKey: val.jobKey,
      dateEmployment: val.admissionDate.format('YYYY-MM-DD'),
      retirementLetter,
      leaseContract,
      commercialPatent,
      taxFolder,
      comments: '',
    }
  }

  get disabledLaborData () {
    const { info } = this

    return Boolean(info?.id)
  }
  }
